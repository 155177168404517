import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"
import ButtonLE from "components/button-le"
import tw, { styled, theme } from "twin.macro"
import PlusIcon from "images/icons/plus.inline.svg"
import MinusIcon from "images/icons/minus.inline.svg"
import { motion, AnimatePresence } from "framer-motion"

const IndustryReinforcementsMobileContainer = styled.div`
  ${tw`divide-y divide-solid divide-priority-lightP4 border-t border-b border-priority-lightP4 mt-10 m:mt-16`}
  .industry {
    ${tw`cursor-pointer py-4 m:py-6`}
    .content-wrapper {
      ${tw`flex flex-row text-white items-start text-lg m:text-lg m:leading-normal font-display`}

      line-height: 175%;

      .icon {
        ${tw`mr-3 mt-1 flex-shrink-0`}

        height: 12px;
        width: 12px;

        &.minus {
          ${tw`hidden`}
        }

        &.plus path {
          fill: ${theme`colors.priority.darkP2`};
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .content-wrapper {
          .icon {
            &.plus path {
              fill: ${theme`colors.neonred.primary`};
            }
          }
        }
      }
    }

    &.active {
      .content-wrapper {
        .icon {
          &.minus {
            ${tw`block`}
            path {
              fill: ${theme`colors.neonred.primary`};
            }
          }

          &.plus {
            ${tw`hidden`}
          }
        }
      }
    }

    .answer {
      ${tw`text-xs m:text-base pl-6`}

      line-height: 175%;

      @media (min-width: 640px) {
        line-height: 175%;
      }

      ul {
        ${tw`list-disc list-inside mt-2`}
        div {
          ${tw`inline`}
        }
      }

      p {
        ${tw`mt-2 first:mt-0`}
      }
    }
  }
`

const IndustryTabsContainer = styled.div`
  ${tw`w-full flex flex-row mb-12 text-white mx-0 justify-items-stretch`}
`
const IndustryTab = styled.div`
  ${tw`font-bold border-b-2 px-2.5 border-b-priority-lightP4 cursor-pointer hover:text-neonred-primary hover:border-b-neonred-primary`}

  width: 126px;
  text-align: center;
  overflow-wrap: break-word;
  width: 126px;
  display: inline-flex;
  justify-content: center;
  font-size: 15px;
  height: 60px;
  flex-wrap: wrap;
  line-height: 1.25;
  align-content: center;

  &.active {
    color: ${theme`colors.neonred.primary`};
    border-bottom: 2px solid ${theme`colors.neonred.primary`};
  }
`
const IndustryDetailsContainer = styled.div`
  ${tw`flex flex-col l:flex-row l:gap-16 gap-8 mx-auto justify-center items-start relative py-11 m:py-16 l:py-0`}
  > * {
    flex: 1;
    width: 100%;
  }
`

const QuestionWrapper = styled.div`
  ${tw`flex flex-row items-center gap-[15px]`}
  padding: 15px;
`
const Question = styled.div`
  ${tw`font-body text-white m-0`} @media(min-width: 640px) {
  }
`
const Image = styled.div`
  ${tw`l:justify-center`}
  img {
    width: 100%;
    border-radius: 10px;
    gap: 10px;
    aspect-ratio: 4/3;
  }

  @media (min-width: 960px) {
    img {
      aspect-ratio: auto;
    }
  }
`
const HeaderIcon = styled.div`
  width: 32px;
  height: 32px;
`
const IndustryTitle = styled.div`
  ${tw`font-body mb-6`}
`
const IndustryDetails = ({ industry }) => {
  return (
    industry && (
      <IndustryDetailsContainer>
        <Image>
          <img src={industry.node.thumbnailImage.url} alt="" />
        </Image>
        <div className="flex flex-col justify-start relative text-white items-start">
          <IndustryTitle>
            Answer sample business questions for {industry.node.title}:
          </IndustryTitle>
          {industry.node.businessQuestions.map((bq) => (
            <QuestionWrapper key={bq.id}>
              <HeaderIcon>
                {bq.headerIcon && <img src={bq.headerIcon.url} alt="" />}
              </HeaderIcon>
              <Question>{bq.question}</Question>
            </QuestionWrapper>
          ))}
          <ButtonLE
            href={`/industry/${industry.node.slug}`}
            className="mt-6"
            color="green"
            target="_blank"
          >
            Find Out More
          </ButtonLE>
        </div>
      </IndustryDetailsContainer>
    )
  )
}
const IndustryTabs = ({ industries, currentIndustry, handleIndustryClick }) => {
  return (
    <IndustryTabsContainer>
      {industries?.map((industry, ind) => (
        <IndustryTab
          key={ind}
          className={`${industry === currentIndustry ? "active" : ""}`}
          onClick={() => handleIndustryClick(industry)}
        >
          {industry.node.title}
        </IndustryTab>
      ))}
    </IndustryTabsContainer>
  )
}
const IndustryReinforcementsDesktop = ({
  industries,
  currentIndustry,
  handleIndustryClick,
}) => {
  useEffect(() => {
    if (!currentIndustry) {
      handleIndustryClick(industries?.[0])
    }
  }, [currentIndustry])

  return (
    <>
      <IndustryTabs
        industries={industries}
        currentIndustry={currentIndustry}
        handleIndustryClick={handleIndustryClick}
      />
      <IndustryDetails industry={currentIndustry} />
    </>
  )
}
const IndustryReinforcementsMobile = ({
  industries,
  currentIndustry,
  handleIndustryClick,
}) => {
  return (
    <IndustryReinforcementsMobileContainer>
      {industries?.map((industry, ind) => (
        <div
          className={`industry ${currentIndustry === industry ? "active" : ""}`}
          key={ind}
          onClick={() => handleIndustryClick(industry)}
        >
          <div className="content-wrapper">
            <PlusIcon className="icon plus" />
            <MinusIcon className="icon minus" />
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: industry.node.title,
              }}
            />
          </div>
          <AnimatePresence>
            {currentIndustry === industry && (
              <motion.div
                className="answer"
                animate={{
                  opacity: 1,
                  height: "auto",
                  marginTop: 1,
                }}
                initial={{ opacity: 0, height: 0, marginTop: 0 }}
                exit={{ opacity: 0, height: 0, marginTop: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <IndustryDetails industry={currentIndustry}></IndustryDetails>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </IndustryReinforcementsMobileContainer>
  )
}
const IndustryReinforcements = () => {
  const isDesktop = useMediaQuery({ minWidth: 1200 })
  //console.log({ isDesktop })
  const data = useStaticQuery(graphql`
    query IndustryReinforcementsContent {
      gcms {
        contentBlocks(where: { identifier: "industry-bubbles" }, first: 1) {
          title
          headerContent {
            html
          }
        }
        industriesConnection(orderBy: order_ASC) {
          edges {
            node {
              id
              title
              headerCopy {
                html
              }
              slug
              thumbnailImage {
                url
              }
              # limit first 5 QA
              businessQuestions(first: 5) {
                id
                question
                headerIcon {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  const industries = data.gcms.industriesConnection?.edges

  const [currentIndustry, setCurrentIndustry] = useState(industries?.[0])

  const handleAccordionClick = (industry) => {
    if (!industry || currentIndustry === industry) {
      setCurrentIndustry(null)
    } else {
      setCurrentIndustry(industry)
    }
  }

  return (
    <>
      {isDesktop ? (
        <IndustryReinforcementsDesktop
          industries={industries}
          currentIndustry={currentIndustry}
          handleIndustryClick={setCurrentIndustry}
        />
      ) : (
        <IndustryReinforcementsMobile
          industries={industries}
          currentIndustry={currentIndustry}
          handleIndustryClick={handleAccordionClick}
        />
      )}
    </>
  )
}

export default IndustryReinforcements
