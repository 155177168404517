import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import StyledBlock from "components/newsroom/styled-block"
import IndustryReinforcementsContent from "./industry-reinforcements-content"

const Container = styled.div`
  ${tw`mx-auto l:max-w-l`}
  max-width: 90%;
`

const Paragraph = styled.div`
  ${tw`font-body text-white text-center m:text-left pt-6 pb-16 l:px-40 m:px-16`}
`

const IndustryReinforcements = () => {
  const data = useStaticQuery(graphql`
    query IndustryReinforcements {
      gcms {
        contentBlocks(where: { identifier: "industry-bubbles" }, first: 1) {
          title
          headerContent {
            html
          }
        }
      }
    }
  `)

  const industryBubbles = data.gcms.contentBlocks?.[0]
  if (!industryBubbles) return null

  return (
    <Container className="mb-10 m:mb-[100px] l:mb-[200px]">
      <Paragraph>
        <StyledBlock
          className="secondary-section-header page-header"
          dangerouslySetInnerHTML={{
            __html: `<h2>${industryBubbles?.title}</h2>${industryBubbles?.headerContent?.html}`,
          }}
        />
      </Paragraph>
      <IndustryReinforcementsContent />
    </Container>
  )
}

export default IndustryReinforcements
