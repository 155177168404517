import React from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"
import Divider from "components/divider"
import PageHeader from "components/page-header"
import MonetizeDemandSection from "components/sections/monetize-demand"
import LightSlantedSection from "components/sections/light-slant"
import ArticleShowcase from "components/sections/article-showcase"
import FeaturedTestimonial from "components/sections/testimonial"
import FeaturedVideo from "components/sections/featured-video"
import PartnerLogos from "components/partner-logos"
import VideoModal from "components/sections/video-modal"
import BasicBackgroundSection from "components/basic-background-section"
import BgApplications from "images/bg/applications.jpg"
import FeaturedPressCarousel from "components/featured-press-carousel"
import IndustryReinforcements from "components/industry-reinforcements"

import { getSharePreviewUrls } from "utils/share-preview"

const AppCoverFrame = styled.div`
  position: relative;
  background: url(${(props) => props.bg});
  background-size: cover;
  border-color: ${(props) => props.color};
  border-bottom-width: 2px;
  margin-bottom: 24px;
  height: 300px;
  width: 300px;
  transition: background-color 0.2s ease;
  @media (min-width: 640px) {
    height: 490px;
    width: 490px;
  }

  &:hover {
    background: url(${(props) => props.bg});
    background-size: cover;
  }
`

const Overlay = styled.div`
  ${tw`bg-black`}
  height: 100%;
  width: 100%;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.4;
  }
`

const AppCover = ({ children, bg, color }) => (
  <AppCoverFrame bg={bg} color={color}>
    <Overlay />
    <div className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-full pointer-events-none">
      {children}
    </div>
  </AppCoverFrame>
)

const Solutions = ({ data }) => {
  const {
    page,
    applicationsConnection: { edges: apps },
  } = data.gcms

  const headerContent = page?.headerContent?.html || ""

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <PageHeader
        bg={page?.headerImage ? page.headerImage.url : false}
        contentClass="solutions"
        content={headerContent}
      >
        <VideoModal
          autoplayHash="why-demand-video"
          className="py-16 m:py-24 l:py-32 relative"
          caption={"Why demand?"}
          data={page.extraData}
        />
      </PageHeader>
      <Divider fill="#F7BC2D" />
      <BasicBackgroundSection
        image={BgApplications}
        color="#000000"
        repeat="repeat-y"
        size="100% auto"
        bgPosition="center"
        className="py-16 m:pb-[6.25rem] l:pb-[12.5rem]"
      >
        <IndustryReinforcements />

        {!!page && !!page.featuredTestimonial && (
          <FeaturedTestimonial testimonial={page.featuredTestimonial} />
        )}
        {!!page && !!page.featuredVideo && (
          <FeaturedVideo video={page.featuredVideo} />
        )}
        {!!page && !!page.featuredLogosClients && (
          <div className="py-16 mx-auto max-w-s m:max-w-m l:max-w-l">
            <PartnerLogos data={page.featuredLogosClients} />
          </div>
        )}
      </BasicBackgroundSection>
      <LightSlantedSection>
        {page?.articleShowcase?.length > 0 && (
          <div>
            <ArticleShowcase articles={page.articleShowcase} />
          </div>
        )}
        {page?.featuredPressArticles?.length > 0 && (
          <FeaturedPressCarousel
            articles={page.featuredPressArticles}
            className="mt-4 mb-12 l:mb-24"
          />
        )}
      </LightSlantedSection>

      <MonetizeDemandSection
        cta="Discover DEMAND360"
        title="Welcome to the era of empirical measurement"
        url="/products/demand360/lite"
      >
        Discover how <span className="demand360-brand">DEMAND360</span>’s
        patented technology extracts signal from the noise on a daily basis as
        the industry’s first global content demand measurement system.
      </MonetizeDemandSection>
    </Layout>
  )
}

export default Solutions

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/solutions" }) {
        ...PageData
      }
      applicationsConnection {
        edges {
          node {
            title
            slug
            body {
              html
            }
            coverImage {
              url
            }
            highlightColor {
              hex
            }
            icon {
              url
            }
            industries {
              slug
              title
            }
            industriesMain {
              title
              slug
            }
          }
        }
      }
    }
  }
`
